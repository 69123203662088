import React, { useEffect } from 'react';
import RichContentItem from '../../components/RichContentItem';
import { useContactQuery } from './contact.query.graphql.generated';
import { PublicationStatus } from '../../lib/api.types';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/LoadingSpinnerWorm';
import { AppContext } from '../../context/AppContext';

const Contact: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useContactQuery({
        variables: {
            status: PublicationStatus.Published,
            locale: currLang,
            }
        }
    );

    useEffect(() => {
        console.log(data);
        if (appContext && data) {
            appContext.setPageTitle(data?.contact?.title || '');
        }
    }, [data, appContext]);

    return (
        <div>
            {loading && <LoadingSpinner />}
            {error && <p>{t('general.error')}: {error.message}</p>}
            {data && (
                data && data.contact && <RichContentItem content={{ ...data.contact, title: data.contact.title || '', richContent: data.contact.richContent || [] }} />
            )}
        </div>
    );
};

export default Contact;