import React from 'react';
import './LoadingSpinnerWorm.css';

const LoadingSpinnerWorm: React.FC = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="dot" />
        ))}
      </div>
    </div>
  );
};

export default LoadingSpinnerWorm;