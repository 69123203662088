import { useState, useEffect } from 'react';

const useFormatDate = (date: Date, locale: string = 'en-US', options?: Intl.DateTimeFormatOptions) => {
    const [formattedDate, setFormattedDate] = useState<string>('');

    useEffect(() => {
        const formatter = new Intl.DateTimeFormat(locale, options);
        setFormattedDate(formatter.format(date));
    }, [date, locale, options]);

    return formattedDate;
};

export default useFormatDate;