import React, { useEffect } from 'react';
import { useHomeQuery } from './home.query.graphql.generated';
import { PublicationStatus } from '../../lib/api.types';
import { useTranslation } from 'react-i18next';
import LatestArticles  from '../../components/LatestArticles';
import RichContentItem from '../../components/RichContentItem';
import LoadingSpinnerWorm from '../../components/LoadingSpinnerWorm';
import { AppContext } from '../../context/AppContext';
import OnTextLanguageSwitcher from '../../components/OnTextLanguageSwitcher';


const Home: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useHomeQuery({
        variables: {
            status: PublicationStatus.Published,
            locale: currLang,
            }
        }
    );

    useEffect(() => {
        if (appContext && data) {
            appContext.setPageTitle(data?.home?.title || '');
        }
    }, [data, appContext]);

    console.log("AppContext: ", appContext);

    return (
        <div>
            {loading && <LoadingSpinnerWorm />}
            {error && <p>{t('general.error')}: {error.message}</p>}
            
            {data && data.home &&  
                data && data.home && <RichContentItem content={{ ...data.home, title: data.home.title || '', richContent: data.home.richContent || [] }} />
            }
            <OnTextLanguageSwitcher />
            <LatestArticles />
        </div>
    );
};

export default Home;