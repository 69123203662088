import React, { useState } from 'react';
import styled from 'styled-components';

interface SidemenuProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const SidemenuOverlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    opacity: ${props => props.isOpen ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
`;

const SidemenuContent = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 300px;
    height: 100%;
    background: linear-gradient(60deg, rgba(16,17,25,1) 0%, rgba(65,158,199,1) 100%);;
    z-index: 1001;
    transform: translateX(${props => props.isOpen ? '0' : '-100%'});
    transition: transform 0.3s ease-in-out;
    padding: 20px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    border-right:2px solid #243f51;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    color:#f4f4f4;
`;

const Sidemenu: React.FC<SidemenuProps> = ({ isOpen, onClose, children }) => {
    return (
        <>
            <SidemenuOverlay isOpen={isOpen} onClick={onClose} />
            <SidemenuContent isOpen={isOpen}>
                <div className="sidemenu">
                <CloseButton onClick={onClose}>&times;</CloseButton>
                {children}
                </div>
            </SidemenuContent>
        </>
    );
};

export default Sidemenu;