import React, { useEffect } from 'react';
import { useArticlesQuery } from './latestArticles.query.graphql.generated';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Article, PublicationStatus } from '../../lib/api.types';
import './latestArticles.css';
import LoadingSpinnerWorm from '../LoadingSpinnerWorm';
import { formatDate } from '../../utils/formatDate';


const LatestArticles: React.FC = () => {
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useArticlesQuery(
        {
            variables: {
                locale: currLang,
                pagination: { pageSize: 4, page: 1 },
                sort: "publishedAt:desc",
                status: PublicationStatus.Published,
            },
        }
    );

    const getArticleLink = (article: Article ) => {
        let articleDate = new Date(article?.publishedAt);
        let year = articleDate.getFullYear();
        let month = articleDate.getMonth() + 1;
        return `/articles/${year}/${month}/${article?.documentId}/${article?.slug}`;
    }

    const getFormattedDate = (date: string) => {
        return formatDate(new Date(date), currLang, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    }

    return (
        <div className='latest-articles'>
            
            {loading && <LoadingSpinnerWorm />}
            {error && <p>{t('general.error')}: {error.message}</p>}
            {data && data.articles?.length > 0 && 
                <>
                    <h2>{t('articles.latestArticles')}</h2>
                    <div className='latest-articles-grid'>
                        {data.articles.filter((article): article is Article => article !== null).map((article, index) => (
                            <div className="article-preview" key={index}>
                                {article?.cover && (
                                    <img src={process.env.REACT_APP_MEDIA_URI + article.cover.url} alt={article.cover.alternativeText ?? ''} />
                                )}
                                {!article?.cover && (
                                    <div className="placeholder-image">&nbsp;</div>
                                )}
                                <h3>{article?.title}</h3>
                                <p>{article?.description} </p>
                                <p className="meta">{t('articles.published')}: {getFormattedDate(article?.publishedAt)} {t('articles.by')} {article.author?.name}</p>
                                {/* {article?.author?.name ? `change this ${t(article?.author.name ? "" : $('foobar'))}` : ''} */}
                                <p><Link to={getArticleLink(article)}>{t('articles.readMore')}...</Link></p>
                            </div>
                        ))} 
                    </div>
                </>
            }
        </div>
    );
};

export default LatestArticles;