// ContentRenderer.tsx
import React from 'react';
import { AboutBlocksDynamicZone } from '../../lib/api.types';
import RichTextRenderer from '../RichTextRenderer';

const ImageBlock: React.FC<{ 
  file: AboutBlocksDynamicZone & { __typename: 'ComponentSharedMedia' } 
}> = ({ file }) => (
  <div className="content-image">
    <img
      src={`${process.env.REACT_APP_MEDIA_URI}${file.file?.url}`}
      alt={file.file?.alternativeText || ''}
      style={{
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        margin: '20px 0'
      }}
    />
    {file.file?.caption && (
      <figcaption className="image-caption">
        {file.file.caption}
      </figcaption>
    )}
  </div>
);

const BlockItems: React.FC<{ blocks: AboutBlocksDynamicZone[] }> = ({ blocks }) => {
  return (
    <div className="content-blocks">
      {blocks.map((block, index) => {
        switch (block.__typename) {
          case 'ComponentSharedMedia':
            return <ImageBlock key={`media-${index}`} file={{ ...block, __typename: 'ComponentSharedMedia' }} />;
          
          case 'ComponentSharedRichText':
            return block.body ? (
              
              <RichTextRenderer key={`richtext-${index}`} body={block.body} />
            ) : null;
          
          default:
            return null;
        }
      })}
    </div>
  );
};

export default BlockItems;