import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

export const client = new ApolloClient({
    link: new HttpLink({
    uri: process.env.REACT_APP_BACKEND_URI,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    }
    }),
    cache: new InMemoryCache(),
});
 