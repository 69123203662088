import { Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/404';
import BaseLayout from './layout/BaseLayout';
import { baseRoutes } from './navigation/AllRoutes';
import { client } from './lib/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import Article from './pages/Article';
import { AppContextProvider } from './context/AppContext';
import { getStoredLanguage } from './utils/LanguageStorage';
import { useEffect } from 'react';
import i18n from './i18n';

function App() {

  useEffect(() => {
    const storedLang = getStoredLanguage();
    i18n.changeLanguage(storedLang);
  }, []);

  return (
      <ApolloProvider client={client}>
        <AppContextProvider>
          <Routes>
            <Route path="/" element={<BaseLayout />}>
                {baseRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={route.element}>
                      {/* {route.children?.map((child) => (
                      <Route key={child.path} path={child.path} element={child.element} />
                      ))} */}
                  </Route>
                ))}
              <Route path="/articles/:articleyear/:articlemonth/:id/:slug" element={<Article />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
          </AppContextProvider>
        </ApolloProvider>
      );
    }

export default App;