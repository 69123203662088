// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.latest-articles {
    margin-top:30px;
}
div.latest-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 30px;
}
div.article-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.3s;
}

div.article-preview p.meta {
    font-size: 12px;
    text-align: left;
    margin-top: -8px;
    margin-bottom: 0px;
}


div.article-preview:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

div.article-preview img {
    width: 100%;
    height:180px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 20px;
}

div.article-preview div.placeholder-image {
    width: 100%;
    height: 180px;
    background-color: #f0f0f0;
    border-radius: 6px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/LatestArticles/latestArticles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,2DAA2D;IAC3D,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;;AAGA;IACI,uCAAuC;IACvC,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["div.latest-articles {\n    margin-top:30px;\n}\ndiv.latest-articles-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    grid-gap: 20px;\n    margin-top: 30px;\n}\ndiv.article-preview {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 0 0 20px 0;\n    padding: 20px;\n    border-radius: 6px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    transition: all 0.3s;\n}\n\ndiv.article-preview p.meta {\n    font-size: 12px;\n    text-align: left;\n    margin-top: -8px;\n    margin-bottom: 0px;\n}\n\n\ndiv.article-preview:hover {\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n    transform: translateY(-5px);\n}\n\ndiv.article-preview img {\n    width: 100%;\n    height:180px;\n    object-fit: cover;\n    border-radius: 6px;\n    margin-bottom: 20px;\n}\n\ndiv.article-preview div.placeholder-image {\n    width: 100%;\n    height: 180px;\n    background-color: #f0f0f0;\n    border-radius: 6px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
