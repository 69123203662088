import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>{t('404NotFound.title')}</h1>
            <p>{t('404NotFound.message')}.</p>
            <Link to="/">{t('404NotFound.backToHome')}</Link>  ||  <Link 
                to={'..'} 
                onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}>{t('404NotFound.backToPrevious')}</Link>
        </div>
    );
};

export default NotFoundPage;