// RichTextRenderer.tsx
import React from 'react';

interface RichTextProps {
  body: string;
}
const RichTextRenderer: React.FC<RichTextProps> = ({ body }) => {
  const processInlineFormatting = (text: string) => {
    // Handle bold text
    return text
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a target="_blank" href="$2">$1</a>')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const formatText = (text: string) => {
    return text.split('\n\n').map((paragraph, index) => {
      if (!paragraph.trim()) return null;

      // Handle headings
      if (paragraph.startsWith('#')) {
        const headingMatch = paragraph.match(/^(#{1,4})\s(.+)$/);
        if (headingMatch) {
          const level = headingMatch[1].length;
          const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
          return (
            <HeadingTag key={index} className={`heading-${level}`}>
              {processInlineFormatting(headingMatch[2])}
            </HeadingTag>
          );
        }
      }

      // Handle lists
      if (paragraph.trim().startsWith('•') || paragraph.trim().startsWith('-')) {
        const items = paragraph.split('\n').filter(item => item.trim());
        return (
          <ul key={index} className="rich-text-list">
            {items.map((item, i) => (
              <li key={i} 
                  dangerouslySetInnerHTML={{
                    __html: processInlineFormatting(item.replace(/^[•-]\s*/, ''))
                  }} 
              />
            ))}
          </ul>
        );
      }

      // Regular paragraphs
      return (
        <p key={index} 
           className="rich-text-paragraph"
           dangerouslySetInnerHTML={{
             __html: processInlineFormatting(paragraph)
           }}
        />
      );
    });
  };

  return (
    <div className="rich-text-content">
      {formatText(body)}
    </div>
  );
};

export default RichTextRenderer;