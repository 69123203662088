import { createContext, useState } from 'react';
import { AppContextTypes } from './AppContext.types';

export const AppContext = createContext<AppContextTypes | null>(null); 

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [appTitle, setAppTitle] = useState('Absum Corporation Oy');
    const [pageTitle, setPageTitle] = useState('Home');

    return (
        <AppContext.Provider
        value={{
                appTitle,
                setAppTitle,
                pageTitle,
                setPageTitle,
            }}
        >
        {children}
    </AppContext.Provider>
    );
};
export default AppContextProvider;

