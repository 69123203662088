import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEn from './lib/locales/en/common.json';
import commonFi from './lib/locales/fi/common.json';

// To more easily support separate json files for each namespace, use 
// https://github.com/i18next/i18next-http-backend
const resources = {
    en: { common: commonEn },
    fi: { common: commonFi }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'fi',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        lng: 'fi',
        ns: ['common'],
        preload: ['en', 'fi'],
        resources,
        supportedLngs: ['en', 'fi'],

    });

export default i18n;