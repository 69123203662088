import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './BaseLayout.css';
import { AppContext } from '../context/AppContext';

const BaseLayout : React.FC = () => {
  const appContext = React.useContext(AppContext);

    useEffect(() => {
      if(appContext) {
        if(appContext.pageTitle === null) {
          document.title = appContext?.appTitle ?? '';
        }
        else {
          document.title = appContext.pageTitle + ' | ' + appContext.appTitle;
        }
      }
    }, [appContext]);
    
      return (
        <>
          <Header />
          <div className="main-content">
            <div className="width-restricted-area">
              <Outlet />
            </div>
          </div>
          <Footer />
        </>
      );
  }
  
  export default BaseLayout;