// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 20px;
  }
  
  .loading-spinner {
    display: flex;
    gap: 4px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #56779a;
    animation: wave 1.2s ease-in-out infinite;
  }
  
  .dot:nth-child(1) { animation-delay: 0s; width:16px; height: 12px; }
  .dot:nth-child(2) { animation-delay: 0.1s; }
  .dot:nth-child(3) { animation-delay: 0.2s; }
  .dot:nth-child(4) { animation-delay: 0.3s; }
  .dot:nth-child(5) { animation-delay: 0.4s; }
  .dot:nth-child(6) { animation-delay: 0.5s; }
  
  @keyframes wave {
    0%, 100% {
      transform: translateY(0);
      background: #446385;
    }
    50% {
      transform: translateY(-20px);
      background: #6594c7;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinnerWorm/LoadingSpinnerWorm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;IACb,QAAQ;EACV;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC;EAC3C;;EAEA,oBAAoB,mBAAmB,EAAE,UAAU,EAAE,YAAY,EAAE;EACnE,oBAAoB,qBAAqB,EAAE;EAC3C,oBAAoB,qBAAqB,EAAE;EAC3C,oBAAoB,qBAAqB,EAAE;EAC3C,oBAAoB,qBAAqB,EAAE;EAC3C,oBAAoB,qBAAqB,EAAE;;EAE3C;IACE;MACE,wBAAwB;MACxB,mBAAmB;IACrB;IACA;MACE,4BAA4B;MAC5B,mBAAmB;IACrB;EACF","sourcesContent":[".loading-spinner-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50px;\n    padding: 20px;\n  }\n  \n  .loading-spinner {\n    display: flex;\n    gap: 4px;\n  }\n  \n  .dot {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background: #56779a;\n    animation: wave 1.2s ease-in-out infinite;\n  }\n  \n  .dot:nth-child(1) { animation-delay: 0s; width:16px; height: 12px; }\n  .dot:nth-child(2) { animation-delay: 0.1s; }\n  .dot:nth-child(3) { animation-delay: 0.2s; }\n  .dot:nth-child(4) { animation-delay: 0.3s; }\n  .dot:nth-child(5) { animation-delay: 0.4s; }\n  .dot:nth-child(6) { animation-delay: 0.5s; }\n  \n  @keyframes wave {\n    0%, 100% {\n      transform: translateY(0);\n      background: #446385;\n    }\n    50% {\n      transform: translateY(-20px);\n      background: #6594c7;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
