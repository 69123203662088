import React, { useEffect } from 'react';
import LatestArticles from '../../components/LatestArticles';
import { useParams } from 'react-router-dom';
import { useArticleQuery } from './article.query.graphql.generated';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import RichContentItem from '../../components/RichContentItem';
import BlockItems from '../../components/BlockItems';
import './article.css';
import { AboutBlocksDynamicZone } from '../../lib/api.types';
import useFormatDate from '../../hooks/useFormatDate';


const Article: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { id } = useParams();
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useArticleQuery({
        variables: {
            documentId: id?.toString() || '',
            locale: currLang,
        },
    });

    const datePublished = useFormatDate(new Date(data?.article?.publishedAt || new Date()), currLang, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    useEffect(() => {
        if (appContext && data) {
            appContext.setPageTitle(data?.article?.title || '');
        }
    }, [data, appContext]);

    
    console.log("Data", data);
    return (
        <>
        <div>
            {loading && <p>{t('general.loading')}...</p>}
            {error && <p>Error: {error.message}</p>}
            {data && (
                <div className="article">
                    
                    {data.article?.cover && <img className="cover" alt={data.article?.cover.alternativeText ?? ''} src={process.env.REACT_APP_MEDIA_URI + data.article?.cover.url} width="100%" style={{maxWidth: `${data.article?.cover.width}px`}} />}
                    {/* <p>{data.article?.category?.name}</p> */}
                    <h1>{data.article?.title}</h1>
                    <div className="article-meta">{t('articles.published')}: {datePublished}. {t('articles.category')}: {data.article?.category?.name}, {t('articles.author').toLowerCase()}: {data.article?.author?.name}.</div>
                    {data.article?.blocks && Array.isArray(data.article.blocks) && <BlockItems blocks={data.article.blocks as AboutBlocksDynamicZone[]} />}

                    {data.article?.content && <RichContentItem content={{ ...data.article, title: undefined, richContent: data.article?.content || [] }} />}
                    {/* <p>{JSON.stringify(data.article)}</p> */}
                    {/* <p>{data.content?}</p> */}
                </div>
            )}
        </div>
        <LatestArticles />
        </>
    );
};

// Filter search https://forum.strapi.io/t/strapi-v4-search-by-slug-instead-id/13469/10

export default Article;