import * as Types from '../../lib/api.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
  status?: Types.InputMaybe<Types.PublicationStatus>;
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', content?: any | null, description?: string | null, documentId: string, slug?: string | null, title?: string | null, publishedAt?: any | null, updatedAt?: any | null, blocks?: Array<{ __typename?: 'ComponentSharedMedia', file?: { __typename?: 'UploadFile', alternativeText?: string | null, caption?: string | null, previewUrl?: string | null, url: string, width?: number | null, height?: number | null } | null } | { __typename?: 'ComponentSharedQuote' } | { __typename?: 'ComponentSharedRichText', body?: string | null } | { __typename?: 'ComponentSharedSlider' } | { __typename?: 'Error', message?: string | null, code: string } | null> | null, category?: { __typename?: 'Category', slug?: string | null, name?: string | null } | null, author?: { __typename?: 'Author', name?: string | null } | null, cover?: { __typename?: 'UploadFile', caption?: string | null, height?: number | null, previewUrl?: string | null, width?: number | null, url: string, name: string, alternativeText?: string | null } | null } | null };


export const ArticleDocument = gql`
    query Article($documentId: ID!, $locale: I18NLocaleCode, $status: PublicationStatus) {
  article(documentId: $documentId, locale: $locale, status: $status) {
    blocks {
      ... on ComponentSharedMedia {
        file {
          alternativeText
          caption
          previewUrl
          url
          width
          height
        }
      }
      ... on ComponentSharedRichText {
        body
      }
      ... on Error {
        message
        code
      }
    }
    category {
      slug
      name
    }
    content
    author {
      name
    }
    cover {
      caption
      height
      previewUrl
      width
      url
      name
      alternativeText
    }
    description
    documentId
    slug
    title
    publishedAt
    updatedAt
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      locale: // value for 'locale'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables> & ({ variables: ArticleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export function useArticleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleSuspenseQueryHookResult = ReturnType<typeof useArticleSuspenseQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;