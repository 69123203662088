import * as Types from '../../lib/api.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticlesQueryVariables = Types.Exact<{
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  status?: Types.InputMaybe<Types.PublicationStatus>;
  filters?: Types.InputMaybe<Types.ArticleFiltersInput>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', title?: string | null, updatedAt?: any | null, slug?: string | null, publishedAt?: any | null, documentId: string, description?: string | null, cover?: { __typename?: 'UploadFile', url: string, width?: number | null, height?: number | null, alternativeText?: string | null, previewUrl?: string | null } | null, category?: { __typename?: 'Category', articles: Array<{ __typename?: 'Article', title?: string | null, slug?: string | null, documentId: string, updatedAt?: any | null, publishedAt?: any | null, author?: { __typename?: 'Author', name?: string | null } | null } | null> } | null, author?: { __typename?: 'Author', name?: string | null } | null } | null> };


export const ArticlesDocument = gql`
    query Articles($locale: I18NLocaleCode, $pagination: PaginationArg, $sort: [String], $status: PublicationStatus, $filters: ArticleFiltersInput) {
  articles(
    locale: $locale
    pagination: $pagination
    sort: $sort
    status: $status
    filters: $filters
  ) {
    title
    updatedAt
    slug
    publishedAt
    documentId
    description
    cover {
      url
      width
      height
      alternativeText
      previewUrl
    }
    category {
      articles {
        title
        slug
        documentId
        updatedAt
        publishedAt
        author {
          name
        }
      }
    }
    author {
      name
    }
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export function useArticlesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesSuspenseQueryHookResult = ReturnType<typeof useArticlesSuspenseQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;