// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.change-langugage-button {
  border: none;
  font-size: 12px;
  background-color: #f7f7f7;
  padding:10px;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  /* float:inline-end; */
  text-decoration: none;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/OnTextLanguageSwitcher/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,qCAAqC;EACrC,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;EACf,WAAW;;EAEX;IACE,WAAW;IACX,qBAAqB;IACrB,qCAAqC;EACvC;AACF","sourcesContent":["button.change-langugage-button {\n  border: none;\n  font-size: 12px;\n  background-color: #f7f7f7;\n  padding:10px;\n  border-radius: 4px;\n  border: 1px solid #f4f4f4;\n  box-shadow: 0 1px 2px rgba(0,0,0,0.1);\n  /* float:inline-end; */\n  text-decoration: none;\n  cursor: pointer;\n  color: #333;\n\n  &:hover {\n    color: #000;\n    text-decoration: none;\n    box-shadow: 0 1px 2px rgba(0,0,0,0.3);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
