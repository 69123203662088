import { HdrOffSelect } from '@mui/icons-material';
import React from 'react';

interface RichContentChild {
    type: string;
    text?: string;
    bold?: boolean;
    url?: string;
    children?: RichContentChild[];
}

interface RichContent {
    type: string;
    children: RichContentChild[];
    level?: number;
}

interface RichContentItemProps {
    title?: string;
    richContent: RichContent[];
}

interface ContentItemProps {
    content: RichContentItemProps;
}

const renderContentToHTML = (content: RichContent[]): string => {
    return content.map(item => {
        if (item.type === "heading" && item.level) {
            return `<h${item.level}>${item.children.map(child => child.text).join('')}</h${item.level}>`;
        } else if (item.type === "paragraph") {
            return `<p>${item.children.map(child => {
                if (child.bold) {
                    return `<strong>${child.text}</strong>`;
                }
                if(child.type === "link") {
                    return `<a href="${child.url}">${child.children ? child.children[0].text : ''}</a>`;
                }
                return child.text;
            }).join('')}</p>`;
        }
        return '';
    }).join('');
};

const RichContentItem: React.FC<ContentItemProps> = ({ content }) => {
    return (
        <React.Fragment>
            {content.title && <h1>{content.title}</h1>}
            <div dangerouslySetInnerHTML={{ __html: renderContentToHTML(content.richContent) }} />
        </React.Fragment>
    );
};


export default RichContentItem;